var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"six-table-container",class:{
      cornered: !_vm.rounded,
    }},[_c('table',{staticClass:"table",class:{'table-with-footer': _vm.totalPages > 1, 'cornered': !_vm.rounded}},[_c('thead',[_c('tr',{staticClass:"table-header",class:{
            'primary-header': _vm.header === 'primary',
          }},_vm._l((_vm.fields),function(field){return _c('th',{key:field.key,staticClass:"header-cell",class:{cornered: !_vm.rounded},style:(field.style)},[_c('h6',{staticClass:"medium uih6 grey"},[_vm._v(" "+_vm._s(field.title)+" ")])])}),0)]),(_vm.loading)?_c('tbody',{class:{loading: _vm.loading}},[(_vm.loading)?_c('tr',[_c('td',{staticClass:"loading-cell",attrs:{"colspan":_vm.fields.length}},[_c('div',{staticClass:"spinner"})])]):_vm._e()]):(_vm.draggable && !_vm.loading)?_c('draggable',{class:_vm.striped ? 'table-body-striped' : '',attrs:{"list":_vm.data,"tag":"tbody"},on:{"end":_vm.onDragEnd}},_vm._l((_vm.data),function(item){return _c('tr',{key:item.id,on:{"click":function($event){return _vm.rowClick(item)}}},_vm._l((_vm.fields),function(field){return _c('td',{key:field.key,staticClass:"table-row uih6",class:{borderedColumn: _vm.borderedColumn},style:(field.style)},[_vm._t(field.key,function(){return [_c('div',[_vm._v(" "+_vm._s(item[field.key])+" ")])]},{"item":item})],2)}),0)}),0):_c('tbody',{class:_vm.striped ? 'table-body-striped' : ''},[(!_vm.data.length)?_c('td',{staticClass:"no-item",attrs:{"colspan":_vm.fields.length}},[_vm._t("no-data",function(){return [_vm._v(" No data found ")]})],2):_vm._l((_vm.paginatedData),function(item){return _c('tr',{key:item.id,class:[{'hoverable-row': _vm.hoverable}, {'striped-row': _vm.striped}],on:{"click":function($event){return _vm.rowClick(item)}}},_vm._l((_vm.fields),function(field){return _c('td',{key:field.key,staticClass:"table-row uih6",class:{borderedColumn: _vm.borderedColumn},style:(field.style)},[_vm._t(field.key,function(){return [_c('div',[_vm._v(" "+_vm._s(item[field.key])+" ")])]},{"item":item})],2)}),0)})],2)],1)]),((_vm.totalPages > 1 || _vm.$slots.footerActions) && !_vm.loading)?_c('div',{staticClass:"footer",class:{
      'footer-with-just-actions': _vm.totalPages <= 1 && _vm.$slots.footerActions,
    }},[(_vm.$slots.footerActions)?[(_vm.totalPages > 1)?_c('div',{staticClass:"footer-with-actions"},[_c('div',{staticClass:"pagination-buttons"},[_c('h6',{staticClass:"pagination-button",class:{disabled: _vm.currentPage === 1},on:{"click":_vm.prevPage}},[_vm._v(" Previous ")]),_c('h6',{staticClass:"pagination-button",attrs:{"class:":{disabled: _vm.currentPage === _vm.totalPages}},on:{"click":_vm.nextPage}},[_vm._v(" Next ")])]),_c('div',{staticClass:"footer-info"},[_vm._v(" Viewing "+_vm._s((_vm.currentPage - 1) * _vm.perPage + 1)+"-"+_vm._s(Math.min(_vm.currentPage * _vm.perPage, _vm.totalRecords))+" of "+_vm._s(_vm.totalRecords)+" results ")])]):_vm._e()]:[_c('div',{staticClass:"pagination-buttons"},[_c('h6',{staticClass:"pagination-button",class:{disabled: _vm.currentPage === 1},on:{"click":_vm.prevPage}},[_vm._v(" Previous ")]),_c('h6',{staticClass:"pagination-button",class:{disabled: _vm.currentPage === _vm.totalPages},on:{"click":_vm.nextPage}},[_vm._v(" Next ")])]),_c('div',{staticClass:"footer-info"},[_vm._v(" Showing "+_vm._s((_vm.currentPage - 1) * _vm.perPage + 1)+"-"+_vm._s(Math.min(_vm.currentPage * _vm.perPage, _vm.totalRecords))+" of "+_vm._s(_vm.totalRecords)+" ")])],_vm._t("footerActions")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }