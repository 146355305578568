<template>
  <div class="six-layout">
    <switch-team-member-modal ref="switchTeamMemberModal" />
    <div>
      <six-navbar @open-loading-modal="showSwitchTeamMemberModal" />
    </div>
    <div class="row">
      <six-sidebar />
      <div class="content">
        <six-banner
          :title="bannerTitle"
          :subtitle="bannerSubtitle" />
        <router-view class="router-view" />

        <six-footer />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchTeamMemberModal from "./SwitchTeamMemberModal.vue";
export default {
  name: "SixLayout",

  components: {
    SwitchTeamMemberModal,
  },

  computed: {
    bannerTitle() {
      if (this.$route.params.banner) {
        return this.$route.params.banner.title;
      }
      if (this.$route.meta.banner) {
        return this.$route.meta.banner.title;
      }
      return "Hello!";
    },

    bannerSubtitle() {
      if (this.$route.params.banner) {
        return this.$route.params.banner.subtitle;
      }
      if (this.$route.meta.banner) {
        return this.$route.meta.banner.subtitle;
      }
      return "Welcome to Sixfab Connect! You can access all features here.";
    },
  },

  methods: {
    showSwitchTeamMemberModal() {
      this.$refs.switchTeamMemberModal.show();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif;
}
.six-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.router-view {
  height: 100%;
  margin-top: -30px;

  padding: 40px;
  padding-top: 0px;
}

.row {
  display: flex;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
}

/* footer */
.content > *:last-child {
  margin-top: auto;
}

@media (max-width: 1440px) {
  .router-view {
    padding: 32px;
    padding-top: 0;
  }
}
</style>
