<template>
  <div
    class="switch"
    :class="[{disabled: disabled}]"
    @click="toggleSwitch">
    <h5
      class="label medium"
      v-if="falseLabel">
      {{ falseLabel }}
    </h5>

    <!-- the slider -->
    <div
      :class="['switch__slider', [`switch__slider--${size}`]]"
      :style="{
        background: switchColor,
        flexDirection: internalValue ? 'row-reverse' : 'row',
      }">
      <!-- the circle -->
      <div
        class="circle"
        :class="[[`circle--${size}`]]"></div>
      <!-- inner text -->
      <slot
        class="slot"
        name="innerText"></slot>
    </div>

    <h5
      class="label medium"
      v-if="trueLabel">
      {{ trueLabel }}
    </h5>
  </div>
</template>

<script>
export default {
  name: "SixSwitch",

  props: {
    value: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "primary",
    },

    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },

    trueValue: {
      default: true,
    },

    falseValue: {
      default: false,
    },

    trueLabel: {
      type: String,
      default: "True",
    },

    falseLabel: {
      type: String,
      default: "False",
    },

    // if the switch is can be toggled
    // if false, the switch will not be able to be toggled, but will still show the value 
    // and it can receive input (it is not disabled)
    toggleable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      internalValue: false,
    };
  },

  computed: {
    switchColor() {
      let colors = {
        primary: "var(--color-primary-solid)",
        secondary: "var(--color-secondary)",
        success: "var(--color-success)",
        danger: "var(--color-danger)",
      };
      if (this.internalValue) {
        return colors[this.color] || this.color || colors.primary;
      }
      return "#ccc";
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.internalValue = newValue;
      },
    },
  },

  methods: {
    toggleSwitch() {
      if(!this.toggleable){
        this.$emit(
          "input",
          this.internalValue ? this.trueValue : this.falseValue
        );
        return;
      }
      if (!this.disabled) {
        this.internalValue = !this.internalValue;
        this.$emit(
          "input",
          this.internalValue ? this.trueValue : this.falseValue
        );
      }
    },
  },
};
</script>

<style scoped>
.switch {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.switch__slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  transition: background 0.5s;
  border-radius: 50px;
}

.switch__slider--small {
  width: 38px;
  height: 20px;
}
.switch__slider--medium {
  width: 55px;
  height: 28px;
}

.switch__slider--large {
  width: 80px;
  height: 36px;
}

.circle {
  border-radius: 50%;
  background-color: #fff;
}

.circle--small {
  width: 11px;
  height: 11px;
}

.circle--medium {
  width: 20px;
  height: 20px;
}

.circle--large {
  width: 24px;
  height: 24px;
}

.label {
  margin: 0 6px !important;
}

@media (max-width: 1440px) {
  .switch__slider--small {
    width: 32px;
    height: 17px;
  }
}
</style>
