<template>
  <div class="button-wrapper">
    <div
      :class="[
        'six-button',
        colorClass,
        sizeClass,
        {
          fullWidth: fullWidth,
          loading: loading,
          disabled: disabled,
          outlined: outlined,
          round: round,
        },
      ]"
      @click="handleClick"
      :disabled="loading || disabled">
      <span v-if="loading">Loading...</span>

      <span
        v-else
        class="slot-container">
        <six-icon
          v-if="icon"
          :name="icon"
          class="container-icon"></six-icon>
        <slot></slot>
      </span>
    </div>
    <div
      v-if="popover && popoverText"
      class="popover">
      {{ popoverText }}
    </div>
  </div>
</template>

<script>
import SixIcon from "../components/sixIcon.vue";
export default {
  name: "sixButton",

  components: {SixIcon},

  props: {
    color: {
      type: String,
      default: "primary",
      validator: (value) =>
        [
          "primary",
          "primary-solid",
          "secondary",
          "success",
          "danger",
          "billing-blue",
          "billing-green",
        ].includes(value),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    click: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popover: {
      type: Boolean,
      default: false,
    },
    popoverText: {
      type: String,
      default: "",
    },
  },

  computed: {
    colorClass() {
      return `color-${this.color}`;
    },
    variantClass() {
      return `variant-${this.variant}`;
    },
    sizeClass() {
      return `size-${this.size}`;
    },
  },

  methods: {
    handleClick(event) {
      if (!this.loading && !this.disabled) {
        if (typeof this.click === "function") {
          // If it's a direct function (from :click), pass the event
          this.click(event);
        } else {
          // For backward compatibility, call without event
          this.click();
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.six-button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  border: 0px transparent;
  border-radius: 4px;
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  text-align: center;
  text-decoration: none;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: background-color 0.3s, color 0.3s ease;
}

.color-primary {
  background: var(--color-primary-gradient);
  color: var(--color-primary-text);
}
.color-primary-solid {
  background-color: var(--color-primary-solid);
  color: var(--color-primary-text);
}
.color-secondary {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
}

.color-success {
  background-color: var(--color-success);
  color: var(--color-success-text);
}

.color-danger {
  background-color: var(--color-danger);
  color: var(--color-danger-text);
}

.color-billing-blue {
  background-color: var(--color-billing-blue);
  color: var(--color-billing-blue-text);
}

.color-billing-green {
  background-color: var(--color-billing-green);
  color: var(--color-billing-green-text);
}

.six-button.color-primary-solid:hover {
  background-color: var(--color-primary-hover);
}

.six-button.color-secondary:hover {
  background-color: var(--color-secondary-hover);
}

.six-button.color-success:hover {
  background-color: var(--color-success-hover);
}

.six-button.color-danger:hover {
  background-color: var(--color-danger-hover);
}

.six-button.color-billing-blue:hover {
  background-color: var(--color-billing-blue-hover);
}

.six-button.color-billing-green:hover {
  background-color: var(--color-billing-green-hover);
}

.six-button.loading {
  cursor: wait;
  opacity: 0.5;
  color: var(--color-primary-text);
}

.six-button.disabled {
  cursor: not-allowed;
  background: var(--color-deactive);
  background-color: var(--color-deactive);
  color: var(--color-deactive-text);
}

.six-button.disabled:hover {
  background: var(--color-deactive);
  background-color: var(--color-deactive);
  color: var(--color-deactive-text);
}

.six-button.outlined {
  background-color: transparent;
  border: 1px solid var(--button-outline-color, transparent);
}

.six-button.round {
  border-radius: 100px;
}

.color-primary.outlined {
  --button-outline-color: var(--color-primary-solid);
  background: transparent;
  color: var(--color-primary-solid);
}

.color-primary-solid.outlined {
  --button-outline-color: var(--color-primary-solid);
  color: var(--color-primary-solid);
}

.color-secondary.outlined {
  --button-outline-color: #000000;
  color: var(--color-secondary-text);
}

.color-success.outlined {
  --button-outline-color: var(--color-success);
  color: var(--color-success);
}

.color-danger.outlined {
  --button-outline-color: var(--color-danger);
  color: var(--color-danger);
}

.color-billing-blue.outlined {
  --button-outline-color: var(--color-billing-blue);
  color: var(--color-billing-blue);
}

.color-billing-green.outlined {
  --button-outline-color: var(--color-billing-green);
  color: var(--color-billing-green);
}

.six-button.outlined.loading {
  border: 1px solid var(--color-deactive-text);
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-primary.loading:hover {
  border-color: var(--color-deactive-text);
  background: transparent;
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-primary-solid.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-secondary.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-success.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-danger.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-billing-blue.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-billing-green.loading:hover {
  border-color: var(--color-deactive-text);
  background-color: transparent;
  color: var(--button-outline-disabled-text-color, #8a92a6);
}

.six-button.outlined.color-primary:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-primary-solid:hover {
  border-color: var(--color-primary-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-secondary:hover {
  border-color: var(--color-secondary-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-success:hover {
  border-color: var(--color-success-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-danger:hover {
  border-color: var(--color-danger-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-billing-blue:hover {
  border-color: var(--color-billing-blue-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.color-billing-green:hover {
  border-color: var(--color-billing-green-hover);
  color: var(--color-primary-text);
}

.six-button.outlined.disabled {
  border: 1px solid var(--color-deactive-text);
  color: var(--color-deactive-text);
}

.six-button.outlined.disabled:hover {
  border: 1px solid var(--color-deactive-text);
  background-color: transparent;
  background: transparent;
  color: var(--color-deactive-text);
}

.size-small {
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}
.size-medium {
  padding: 8px 24px;
  font-size: 16px;
}

.size-large {
  padding: 12px 28px;
  font-size: 18px;
}

.container-icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  margin-right: 4px;
  margin-left: -4px;
}

.slot-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullWidth {
  display: block;
  width: 100%;
}

.button-wrapper {
  position: relative;
  display: inline-block;
}

.popover {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -100%);
  color: var(--color-secondary-text);
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 4px;
  z-index: 10;
  white-space: nowrap;
  display: none;
}

.button-wrapper:hover .popover {
  display: block;
}

@media (max-width: 1440px) {
  .size-small {
    padding: 4px 8px;
    font-size: 8px;
  }
  .size-medium {
    padding: 8px 8px;
    font-size: 13px;
  }
}
</style>
