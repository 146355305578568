<template>
  <div>
    <div
      class="six-table-container"
      :class="{
        cornered: !rounded,
      }">
      <table
        class="table"
        :class="{'table-with-footer': totalPages > 1, 'cornered': !rounded}">
        <thead>
          <tr
            class="table-header"
            :class="{
              'primary-header': header === 'primary',
            }">
            <th
              class="header-cell"
              :class="{cornered: !rounded}"
              v-for="field in fields"
              :key="field.key"
              :style="field.style">
              <h6 class="medium uih6 grey">
                {{ field.title }}
              </h6>
            </th>
          </tr>
        </thead>

        <tbody
          v-if="loading"
          :class="{loading: loading}">
          <tr v-if="loading">
            <td
              :colspan="fields.length"
              class="loading-cell">
              <div class="spinner"></div>
            </td>
          </tr>
        </tbody>

        <draggable
          v-else-if="draggable && !loading"
          :list="data"
          tag="tbody"
          :class="striped ? 'table-body-striped' : ''"
          @end="onDragEnd">
          <tr
            v-for="item in data"
            :key="item.id"
            @click="rowClick(item)">
            <td
              class="table-row uih6"
              :class="{borderedColumn: borderedColumn}"
              :style="field.style"
              v-for="field in fields"
              :key="field.key">
              <slot
                :name="field.key"
                :item="item">
                <div>
                  {{ item[field.key] }}
                </div>
              </slot>
            </td>
          </tr>
        </draggable>

        <tbody
          v-else
          :class="striped ? 'table-body-striped' : ''">
          <td
            v-if="!data.length"
            :colspan="fields.length"
            class="no-item">
            <slot name="no-data"> No data found </slot>
          </td>

          <tr
            v-for="item in paginatedData"
            :key="item.id"
            v-else
            :class="[{'hoverable-row': hoverable}, {'striped-row': striped}]"
            @click="rowClick(item)">
            <td
              class="table-row uih6"
              :class="{borderedColumn: borderedColumn}"
              :style="field.style"
              v-for="field in fields"
              :key="field.key">
              <slot
                :name="field.key"
                :item="item">
                <div>
                  {{ item[field.key] }}
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="footer"
      :class="{
        'footer-with-just-actions': totalPages <= 1 && $slots.footerActions,
      }"
      v-if="(totalPages > 1 || $slots.footerActions) && !loading">
      <template v-if="$slots.footerActions">
        <div
          v-if="totalPages > 1"
          class="footer-with-actions">
          <div class="pagination-buttons">
            <h6
              class="pagination-button"
              :class="{disabled: currentPage === 1}"
              @click="prevPage">
              Previous
            </h6>
            <h6
              class="pagination-button"
              @click="nextPage"
              :class:="{disabled: currentPage === totalPages}">
              Next
            </h6>
          </div>
          <div class="footer-info">
            Viewing {{ (currentPage - 1) * perPage + 1 }}-{{ Math.min(currentPage * perPage, totalRecords) }} of {{ totalRecords }} results
          </div>
        </div>
      </template>
      <template v-else>
        <div class="pagination-buttons">
          <h6
            class="pagination-button"
            @click="prevPage"
            :class="{disabled: currentPage === 1}">
            Previous
          </h6>
          <h6
            class="pagination-button"
            @click="nextPage"
            :class="{disabled: currentPage === totalPages}">
            Next
          </h6>
        </div>
        <div class="footer-info">
            Showing {{ (currentPage - 1) * perPage + 1 }}-{{ Math.min(currentPage * perPage, totalRecords) }} of {{ totalRecords }}
        </div>
      </template>
      <slot name="footerActions"></slot>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SixTable",

  components: {
    draggable,
  },

  props: {
    fields: {
      /**
       * schema: [
       *  {
       *    key: "string",
       *    title: "string",
       *    style: "string",
       * },
       */
      type: Array,
      required: true,
    },

    data: {
      type: Array,
      required: true,
    },

    perPage: {
      /**
       * How many data to show per page. For when the pagination is client-side.
       */
      type: Number,
      default: 10,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    striped: {
      type: Boolean,
      default: false,
    },

    borderedColumn: {
      /**
       * Whether to show borders of the columns.
       */
      type: Boolean,
      default: false,
    },

    header: {
      /**
       * Used to change the header color and size. Primary is for overview page
       */
      type: String,
      default: "",
      validator: (value) => {
        return ["primary", "secondary", ""].includes(value);
      },
    },

    pageCount: {
      /**
       * This is used when the pagination is server-side.
       */
      type: Number,
      default: 0,
    },

    totalRecords: {
      /**
       * How many data are there in total.
       */
      type: Number,
      default: 0,
    },

    rounded: {
      type: Boolean,
      default: true,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    hoverable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      height: 0,
    };
  },

  computed: {
    totalPages() {
      if (this.pageCount) {
        return this.pageCount;
      }
      return Math.ceil(this.data.length / this.perPage);
    },

    paginatedData() {
      if (this.pageCount) {
        return this.data;
      }

      if (this.currentPage < 1) {
        this.currentPage = 1;
      }

      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },

    recordCount() {
      if (this.totalRecords) {
        return this.totalRecords;
      }
      return this.data.length;
    },
  },

  watch: {
    totalPages(newTotalPages) {
      if (this.currentPage > newTotalPages) {
        this.currentPage = newTotalPages;
      }
    },
  },

  mounted() {
    this.height = this.$el.clientHeight;
  },

  methods: {
    nextPage() {
      if (this.pageCount) {
        this.$emit("next", this.currentPage);
      }
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    prevPage() {
      if (this.pageCount) {
        this.$emit("previous", this.currentPage);
      }
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    rowClick(item) {
      this.$emit("row-click", item);
    },

    onDragEnd(newData) {
      this.$emit("update:data", this.data);
      this.$forceUpdate();
    },

    resetPagination() {
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.six-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.table {
  width: 100%;
  border-radius: 8px 8px 8px 8px;
  border-collapse: collapse;
  overflow: auto;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
.table-header {
  background-color: #f5f6fa;
}

.table-header th:first-child {
  border-top-left-radius: 8px;
}

.table-header th:last-child {
  border-top-right-radius: 8px;
}

.cornered tr:last-child td:first-child {
  border-radius: 0;
}
.cornered tr:last-child td:last-child {
  border-radius: 0;
}

.six-table-container.cornered {
  border-radius: 0;
}

.cornered th:first-child {
  border-radius: 0;
}

.cornered th:last-child {
  border-radius: 0;
}

.header-cell {
  padding: 8px 24px;
  border: none;
  white-space: nowrap;
}

.primary-header {
  background: linear-gradient(104deg, #357396 0%, #04324d 159.56%);
}

.primary-header .header-cell {
  padding: 12px 26px;
}
.primary-header .header-cell h6 {
  color: white !important;
}

.table-row {
  padding: 16px 24px;
  background-color: white;
  border-bottom: solid 1px #e9ecef;
}

.table-body-striped .striped-row:nth-child(even) .table-row {
  background-color: #f5f6fa;
  transition: background-color 0.14s ease-out;
}

.table-body-striped .striped-row:nth-child(odd) .table-row {
  background-color: white;
  transition: background-color 0.14s ease-out;
}

.table-body-striped .hoverable-row:nth-child(even):hover .table-row {
  cursor: pointer;
  background-color: #e9eaef;
}

.table-body-striped .hoverable-row:nth-child(odd):hover .table-row {
  cursor: pointer;
  background-color: #f4f4f4;
}

.borderedColumn {
  border-right: 1px solid #e9ecef;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 20px 24px;
  background-color: #fff;
  border-top: 1px solid #beb8b8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.footer-with-just-actions {
  display: flex;
  justify-content: flex-end !important;
}

.footer-with-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.footer-info {
  color: rgba(35, 45, 66, 0.39);
  margin-right: 50px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 24.5px */
}

.table-with-footer tr:last-child td:first-child {
  border-bottom-left-radius: 0;
}

.table-with-footer tr:last-child td:last-child {
  border-bottom-right-radius: 0;
}

.pagination-buttons {
  display: inline-flex;
  align-items: stretch;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  background: #fff;
}
.pagination-button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #dee2e6;
  color: #224b62;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.pagination-button:hover {
  background: #224b62;
  color: #fff;
}
.disabled {
  cursor: not-allowed;
  background: #f1f1f1;
  color: #a8a8a8;
  pointer-events: none;
}
.pagination-buttons :first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.pagination-buttons :last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.checkbox {
  margin-right: 16px;
}
.no-item {
  text-align: center;
  background: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  color: #224b62;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  padding: 20px;
}

.loading {
  background: #fff;
  height: 100%;
}

.loading-cell {
  text-align: center;
  padding: 20px 0;
  height: 100%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary-solid);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1440px) {
  .six-table-container {
    overflow-x: auto;
  }

  .pagination-button {
    display: flex;
    padding: 8px 20px;
  }
}
</style>
