import Vue from "vue";
import Router from "vue-router";
import AppLayout from "../components/admin/AppLayout";
import axios from "axios";
import store from "../store";
import SixLayout from "../six-ui/views/SixLayout.vue";

Vue.use(Router);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
};

const router = new Router({
  mode:
    process.env.VUE_APP_ROUTER_MODE_HISTORY === "true" ? "history" : "hash",
  routes: [
    {
      path: "*",
      redirect: {name: "Overview"},
    },
    {
      path: "/terminal",
      name: "terminal",
      component: () =>
        import("../components/assets/views/Core/RemoteTerminal"),
    },
    {
      path: "/alpon-term",
      name: "alponTerm",
      component: () =>
        import("../six-ui/views/assetDetail/RemoteTerminal.vue"),
    },
    {
      path: "/login",
      component: () => import("../components/auth/Login.vue"),
      name: "login",
    },
    {
      name: "default",
      path: "/",
      component: SixLayout,
      redirect: {name: "Overview"},
      meta: {requiresAuth: true},
      children: [
        {
          name: "Overview",
          path: "/",
          component: () => import("../six-ui/views/Overview.vue"),
          meta: {
            banner: {
              title: "Hello",
              subtitle:
                "Welcome to Sixfab Connect! Seamlessly manage your assets and tools here.",
            },
            teamPermissions: ["overview.view"],
          },
        },
        {
          name: "FAQ",
          path: "faq",
          component: () => import("../six-ui/views/FAQ.vue"),
          meta: {
            banner: {
              title: "Frequently Asked Questions",
              subtitle: "Find answers to the most common questions here.",
            },
          },
        },
        {
          name: "datapools",
          path: "datapools",
          component: () => import("../six-ui/views/Datapools.vue"),
          meta: {
            banner: {
              title: "Manage Data Pools",
              subtitle: "Monitor and adjust your data pools efficiently.",
            },
            teamPermissions: ["datapools.view"],
          },
        },
        {
          name: "profile",
          path: "profile",
          component: () => import("../six-ui/views/profile/Profile.vue"),
          meta: {
            banner: {
              title: "Profile & Team Management",
              subtitle:
                "Manage your profile, team members, and permissions here.",
            },
            teamPermissions: ["profile.view"],
          },
        },
        {
          name: "Developer",
          path: "developer",
          component: () => import("../six-ui/views/developer/Developer.vue"),
          meta: {
            banner: {
              title: "API Keys",
              subtitle: "Manage your API keys and access developer resources.",
            },
            teamPermissions: ["developers.view"],
          },
        },
        {
          name: "CostSummary",
          path: "usage/cost-summary",
          component: () => import("../six-ui/views/CostSummary.vue"),
          meta: {
            banner: {
              title: "Cost Summary",
              subtitle: "Review your cost details here.",
            },
            teamPermissions: ["usage.view"],
          },
        },
        {
          name: "AssetList",
          path: "assets",
          component: () => import("../six-ui/views/assetList/AssetList.vue"),
          meta: {
            banner: {
              title: "Assets",
              subtitle: "View and manage all your assets.",
            },
            teamPermissions: ["assets.view"],
          },
        },
        {
          name: "RegisterAssetLink",
          path: "r/:sn",
          component: () => import("../six-ui/views/assetList/AssetList.vue"),
          meta: {
            banner: {
              title: "Assets",
              subtitle: "View and manage all your assets.",
            },
            teamPermissions: ["assets.register"],
          }
        },
        {
          name: "Fleets",
          path: "fleets",
          component: () => import("../six-ui/views/fleets/Fleets.vue"),
          meta: {
            banner: {
              title: "Manage Fleets",
              subtitle: "Organize and control your fleets here.",
            },
            teamPermissions: ["fleets.view"],
          },
        },
        {
          name: "Activities",
          path: "activities",
          component: () => import("../six-ui/views/Activities.vue"),
          meta: {
            banner: {
              title: "Recent Activities",
              subtitle: "Monitor the latest activities of your assets.",
            },
          },
        },
        {
          name: "AssetOverview",
          path: "assets/:assetID",
          component: () => import("../six-ui/views/assetDetail/AssetView.vue"),
          meta: {
            banner: {
              title: "Asset Details",
              subtitle: "Manage and monitor your asset here.",
            },
            teamPermissions: ["assets.view"],
          },
          children: [
            {
              name: "AssetOverview",
              path: "",
              meta: {
                banner: {
                  title: "Asset Details",
                  subtitle: "Manage and monitor your asset here.",
                },
              },
            },
            {
              name: "DeviceTab",
              path: "device",
              meta: {
                banner: {
                  title: "Asset Details",
                  subtitle: "Manage and monitor your asset here.",
                },
              },
            },
            {
              name: "NetworkTab",
              path: "network",
              meta: {
                banner: {
                  title: "Asset Details",
                  subtitle: "Manage and monitor your asset here.",
                },
              },
            },
            {
              name: "ApplicationTab",
              path: "application",
              meta: {
                banner: {
                  title: "Asset Details",
                  subtitle: "Manage and monitor your asset here.",
                },
              },
            },
          ],
        },
        {
          name: "CoreDashboard",
          path: "assets/:assetID/core",
          component: () => import("../six-ui/views/core/CoreDashboard.vue"),
          meta: {
            banner: {
              title: "CORE Device Dashboard",
              subtitle: "Manage and monitor your CORE device here.",
            },
            teamPermissions: ["assets.view"],
          },
        },
        {
          name: "TotalDataUsage",
          path: "usage/total",
          props: true,
          component: () => import("../six-ui/views/TotalDataUsage.vue"),
          meta: {
            banner: {
              title: "Total Cellular Data Usage",
              subtitle:
                "Track your cellular data consumption with Sixfab SIMs here.",
            },
            teamPermissions: ["usage.view"],
          },
        },
        {
          name: "Pricing",
          path: "pricing",
          component: () => import("../six-ui/views/Pricing.vue"),
        },
        {
          name: "Billing",
          path: "billing/",
          component: () => import("../six-ui/views/billing/Billing.vue"),
          meta: {
            banner: {
              title: "Billing",
              subtitle:
                "Manage your billing preferences and view transactions.",
            },
            teamPermissions: ["billing.view"],
          },
        },
        {
          name: "AddCredits",
          path: "billing/addcredits",
          component: () => import("../six-ui/views/billing/Billing.vue"),
          meta: {
            banner: {
              title: "Billing",
              subtitle:
                "Manage your billing preferences and view transactions.",
            },
            teamPermissions: ["billing.view"],
          },
        },
        {
          name: "Registry",
          path: "registry",
          component: () => import("../six-ui/views/registry/RegistryView.vue"),
          meta: {
            banner: {
              title: "Container Registry",
              subtitle: "Manage your container images here.",
            },
            teamPermissions: ["registry.view"],
          },
          props: (route) => ({
            username: route.params.username || "metekaya",
          }),
        },
        {
          name: "RegistryTagDetail",
          path: "registry/user/:username/image/:image/tag/:tag",
          component: () => import("../six-ui/views/registry/TagDetail.vue"),
          meta: {
            banner: {
              title: "Container Registry",
              subtitle: "Manage your container images here.",
            },
            teamPermissions: ["registry.view"],
          },
        },
        {
          name: "TeamsRedirect",
          path: "teams-redirect",
          component: () => import("../six-ui/views/TeamsRedirect.vue"),
          meta: {
            banner: {
              title: "Hello!",
              subtitle: "Welcome to Sixfab Connect!",
            },
          },
        },
        {
          name: "Notifications",
          path: "notifications",
          component: () => import("../six-ui/views/Notifications.vue"),
          meta: {
            banner: {
              title: "Notifications",
              subtitle: "Check your latest notifications and updates here.",
            },
            teamPermissions: ["notifications.view"],
          },
        },
      ],
    },
    {
      path: "/masa",
      component: SixLayout,
      meta: {requiresAuth: true, requiredPermissions: ["backoffice"]},
      children: [
        {
          name: "backoffice",
          path: "/",
          meta: {requiresAuth: true, requiresBackofficeToken: true},
          component: () => import("../components/backoffice/Backoffice.vue"),
        },
        {
          name: "BoAccess",
          path: "access",
          meta: {requiresAuth: true, requiresBackofficeToken: false},
          component: () => import("../components/backoffice/BoAccess.vue"),
        },
        {
          name: "BoAssetDetails",
          path: "asset/:assetID",
          meta: {requiresAuth: true, requiresBackofficeToken: true},
          component: () =>
            import("../components/backoffice/BoAssetDetails.vue"),
        },
        {
          name: "customer",
          path: "customers/:id",
          meta: {requiresAuth: true, requiresBackofficeToken: true},
          component: () => import("../components/backoffice/BoCustomer.vue"),
        },
        {
          name:'BoLogs',
          path:'logs',
          meta: { requiresAuth: true, requiresBackofficeToken:true },
          component: () => import('../components/backoffice/BoLogs.vue'),
        }
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (window.location.hash.includes("access_token")) {
      next({name: "login", params: {auth0Data: window.location.hash}});
      return;
    }

    if (!store.getters.isAuthenticated) {
      // User info does not exist, fetch it
      axios
        .get("/auth/user_info", {
          withCredentials: true,
          baseURL: process.env.VUE_APP_API_ROOT,
        })
        .then((response) => {
          const userInfo = response.data;
          store.commit("AUTH_SUCCESS", userInfo);
          next();
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
          next({name: "login", params: {redirectUrl: to.fullPath}});
        });

      return; // Stop the navigation until user info is fetched
    } else if (store.getters.isAuthenticated) {
      const customer = store.getters.customer;

      if (to.matched.some((m) => m.meta.requiredGroups)) {
        let customerGroups = customer.groups || [];
        if (
          !to.matched.some(
            (m) =>
              m.meta.requiredGroups &&
              m.meta.requiredGroups.every((el) => customerGroups.includes(el))
          )
        ) {
          next({name: "Overview"});
          return;
        }
      }

      if (customer.acting_as !== null) {
        let actingAsEmail = customer.acting_as;
        let actingPermissions = [];

        for (let member of customer.member_of) {
          if (member.email === actingAsEmail) {
            actingPermissions = member.permissions;
            break;
          }
        }

        if (to.matched.some((m) => m.meta.teamPermissions)) {
          if (
            !to.matched.some(
              (m) =>
                m.meta.teamPermissions &&
                m.meta.teamPermissions.every((el) =>
                  actingPermissions.includes(el)
                )
            )
          ) {
            next({name: "TeamsRedirect"});
            return;
          }
        }
      } else {
        if (to.matched.some((m) => m.meta.requiredPermissions)) {
          let customerPermissions = customer.permissions || [];
          if (
            !to.matched.some(
              (m) =>
                m.meta.requiredPermissions &&
                m.meta.requiredPermissions.every((el) =>
                  customerPermissions.includes(el)
                )
            )
          ) {
            next({name: "Overview"});
            return;
          }
        }
      }

      // authenticated and has required permissions & groups but need backoffice token
      if (to.matched.some((m) => m.meta.requiresBackofficeToken)) {
        if (
          !store.getters.backofficeToken ||
          !store.getters.decodedBackofficeToken ||
          new Date() / 1000 > store.getters.decodedBackofficeToken.exp
        ) {
          next({name: "BoAccess", params: {redirect_url: to.fullPath}});
          return;
        }
      }
      // has a valid backoffice token, prevent going back to BoAccess
      if(to.name === "BoAccess" && store.getters.backofficeToken && store.getters.decodedBackofficeToken && (new Date() / 1000) < store.getters.decodedBackofficeToken.exp){
        router.go(-1)
        return
      }

      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// logout backoffice before leaving backoffice routes
router.afterEach((to, from) => {
  if (from.matched.some(m => m.meta.requiresBackofficeToken) && !to.matched.some(m => m.meta.requiresBackofficeToken)) {
    store.commit("setFilters", {
      tag: [],
      type: "",
      simStatus: "",
    });
    store.commit("setSearchQuery", "");
    store.dispatch('logout_backoffice')
  }
});

export default router;
