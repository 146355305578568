<template>
  <div class="navbar">
    <div class="logo">
      <img
        src="../media/icons/Logo.svg"
        alt="logo" />
      <six-icon
        name="leftArrow"
        class="toggle-button"
        @click="toggleSidebar"
        clickable
        :style="{
          transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
        }"></six-icon>
    </div>
    <div
      class="menu"
      v-if="menuOpen">
      <ul>
        <li
          class="menu-item"
          :class="item.class"
          v-for="item in menu"
          :key="item.name">
          <h6 class="grey">
            <a :href="item.link">{{ item.name }}</a>
          </h6>
        </li>
        <li
          v-if="hasPermission('notifications.view')"
          class="notification-bell menu-item">
          <div class="vertical-border"></div>
          <div
            class="notification-container"
            @click="navigateToNotifications">
            <six-icon
              name="notification"
              clickable></six-icon>
            <div
              v-if="hasPendingInvitations"
              class="notification-badge">
              {{ pendingInvitationsCount }}
            </div>
          </div>
          <div class="vertical-border"></div>
        </li>
        <div
          v-else
          class="vertical-border"
          :style="{
            marginLeft: 32 + 'px',
          }"></div>
        <li class="menu-item">
          <six-select
            class="team-select"
            :options="teamMembersOptions"
            @input="teamMemberChanged($event)"
            :fullWidth="true"
            :flat="true"
            :placeholder="
              teamName || selectedTeamMember || 'Select team member'
            " />
          <ul class="profile-options">
            <li
              v-if="!this.$store.getters.customer.acting_as"
              class="profile-option profile-link">
              <router-link to="/profile">Profile</router-link>
            </li>
            <li class="profile-option logout">
              <button
                class="logout-btn"
                @click="logout()">
                Logout
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- this part is for mobile version (when menu is a dropdown component)  -->
    <div
      v-else
      class="menu">
      <six-icon
        name="menu"
        class="menu-toggle-button"
        clickable
        @click="toggleMenuDropdown"></six-icon>

      <transition name="slideDown">
        <div
          v-if="menuDropdownOpen"
          class="menu-dropdown">
          <div class="dropdown-content">
            <ul>
              <li class="menu-item">
                <six-select
                  class="team-select"
                  :options="teamMembersOptions"
                  @input="teamMemberChanged($event)"
                  :fullWidth="true"
                  :flat="true"
                  :placeholder="
                    teamName || selectedTeamMember || 'Select team member'
                  " />
                <ul class="profile-options-dropdown">
                  <li
                    v-if="!this.$store.getters.customer.acting_as"
                    class="profile-option profile-link">
                    <router-link to="/profile">Profile</router-link>
                  </li>
                  <li class="profile-option logout">
                    <button
                      class="logout-btn"
                      @click="logout()">
                      Logout
                    </button>
                  </li>
                </ul>
              </li>

              <li
                v-if="hasPermission('notifications.view')"
                class="menu-item notification-dropdown">
                <div class="notification-container">
                  <six-icon name="notification"></six-icon>
                  <router-link to="Notifications">Notifications</router-link>
                  <div
                    v-if="hasPendingInvitations"
                    class="notification-badge">
                    {{ pendingInvitationsCount }}
                  </div>
                </div>
              </li>

              <li
                class="menu-item"
                :class="item.class"
                v-for="item in menu"
                :key="item.name">
                <a :href="item.link">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SixSelect from "./sixSelect.vue";

export default {
  name: "SixNavbar",

  components: {
    SixSelect,
  },

  data() {
    return {
      menu: [],
      menuOpen: true,
      menuDropdownOpen: false,
      selectedTeamMember: null,
      initialLoad: true, // Track initial load
    };
  },

  computed: {
    isSidebarOpen() {
      return this.$store.getters.sidebar.opened;
    },
    userEmail() {
      return this.$store.getters.customer.email;
    },
    teamMembers() {
      return this.$store.getters.customer.team_members;
    },
    pendingInvitations() {
      return this.$store.getters.customer.pending_invitations_from;
    },
    pendingInvitationsCount() {
      return this.pendingInvitations.length;
    },
    hasPendingInvitations() {
      return this.pendingInvitationsCount > 0;
    },
    actingAs() {
      return this.$store.getters.customer.acting_as || this.userEmail;
    },
    memberOf() {
      return this.$store.getters.customer.member_of;
    },
    teamMembersOptions() {
      let originalUser = {
        label: this.$store.getters.customer.team_name || this.userEmail,
        value: this.userEmail,
        hoverLabel: this.userEmail,
      };
      return this.memberOf
        .map((member) => ({
          label: member.team_name || member.email,
          value: member.email,
          hoverLabel: member.email,
        }))
        .concat(originalUser);
    },

    teamName() {
      if (this.actingAs == this.userEmail) {
        return this.$store.getters.customer.team_name || this.userEmail;
      } else {
        const teamMember = this.memberOf.find(
          (member) => member.email === this.actingAs
        );
        if (teamMember) {
          return teamMember.team_name || teamMember.email;
        } else {
          return "";
        }
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.addEventListener("click", this.handleClickOutside);

    // Set initial selected value to the acting as user's email
    this.selectedTeamMember = this.actingAs;
  },

  methods: {
    toggleSidebar() {
      this.$store.commit("toggleSidebar");
    },

    handleResize() {
      this.menuOpen = window.innerWidth > 955;
    },

    toggleMenuDropdown() {
      this.menuDropdownOpen = !this.menuDropdownOpen;
    },

    handleClickOutside(event) {
      const menu = document.querySelector(".menu-toggle-button");
      if (menu && !menu.contains(event.target)) {
        this.menuDropdownOpen = false;
      }
    },

    logout() {
      this.$store.dispatch("auth_logout", true);
    },

    teamMemberChanged(newMember) {
      this.selectedTeamMember = newMember;
      this.switchTeamMember(newMember);
    },

    switchTeamMember(newTeamMember) {
      this.$emit("open-loading-modal");
      this.axios
        .post("/auth/switch_account", {
          team_member_email: newTeamMember,
        })
        .then(() => {
          window.location.href = "/#/teams-redirect";
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error switching team member:", error);
        });
    },
    navigateToNotifications() {
      this.$router.push({name: "Notifications"});
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.navbar {
  display: flex;
  width: 100%;
  height: 74px;
  padding: 16px 40px;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  backdrop-filter: blur(32px);
  position: relative;
  z-index: 999;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 66px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle-button {
  border-radius: 33px;
  background: #224b62;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 74px;
  right: 5px;
  background: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px 16px 16px 0px;
  z-index: 99999;
}

.slideDown-enter-active,
.slideDown-leave-active {
  transition: all 0.2s;
}
.slideDown-enter,
.slideDown-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.menu-dropdown ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-item {
  display: flex;
  padding: 8px 0px 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0px 32px 32px 0px;
  cursor: pointer;
}

.vertical-border {
  width: 1px;
  height: 74px;
  background: #d9d9d9;
}

.profile-name {
  color: var(--232D42-Text-Color-1, #232d42);
}

.menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu a {
  text-decoration: none;
  color: var(--8A92A6-Text-Color-2, #8a92a6);
}

.notification-bell {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  position: relative;
}
.notification-bell > .icon {
  margin: 0;
  height: 24px;
}

.notification-container {
  position: relative;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.notification-dropdown {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 8px;
}

.profile-options {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 9.5px;
}

.profile-options-dropdown {
  display: flex;
  flex-direction: row !important;
  gap: 8px;
}

.profile-option {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.profile-link a {
  color: #1c5271;
}
.router-link-exact-active,
.router-link-exact-active {
  color: #1c5271;
  background: #fff !important;
}

.logout-btn {
  background: #fff;
  border: none;
  color: #cf142b !important;
  padding: 0;
  cursor: pointer;
}

.select-container ::v-deep .select {
  width: 100% !important;
  min-width: 200px !important;
  overflow: visible !important;
  margin-right: 16px !important;
  white-space: nowrap !important;
}

@media screen and (max-width: 1536px) {
  .select-container ::v-deep .select {
    min-width: 150px !important;
  }
}

@media screen and (max-width: 956px) {
  .notification-badge {
    position: absolute;
    top: -5px;
    right: 60px;
    width: 16px;
    height: 16px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
</style>
