<template>
  <div class="six-banner">
    <div class="banner-text">
      <h1 class="bold">{{ title }}</h1>
      <h4 class="medium">
        {{ subtitle }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "sixBanner",

  props: {
    title: {
      type: String,
      default: "Hello!",
    },
    subtitle: {
      type: String,
      default: "Welcome to Sixfab Connect! You can access all features here.",
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.six-banner {
  width: 100%;
  height: auto;
  max-height: 200px;

  border-radius: 0px 0px 16px 16px;
  background-image: url("../media/icons/sfPattern.svg"),
    linear-gradient(104deg, #357396 0%, #04324d 159.56%);
  background-position: center;
  background-repeat: no-repeat;
  color: #fff !important;
}

.banner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 44px 57px 74px 57px;
}
.banner-text h1,
h4 {
  color: #fff !important;
}
</style>
